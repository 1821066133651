/** @jsx jsx */
import { jsx } from "theme-ui"
import ResponsivePlayer from "../components/responsive-player"

const Container = ({ isFluid, ...props }) => (
  <div
    {...props}
    sx={{
      // minWidth: props.isFluid ? 'none' : '180px',
      width: isFluid ? "none" : "300px",
      transition: "opacity 0.1s ease-in-out",
      ":hover": {
        opacity: 0.7,
      },
    }}
  />
)

const Legend = props => (
  <div
    {...props}
    sx={{
      color: "legend",
      fontSize: "15px",
      fontWeight: 300,
      textAlign: "center",
      marginTop: "0.3rem",
      marginBottom: "0.7rem",
    }}
  />
)

const VideoItem = ({ url, legend, isFluid }) => {
  return (
    <Container isFluid={isFluid}>
      <ResponsivePlayer light controls url={url} playing />
      <Legend>{legend}</Legend>
    </Container>
  )
}

export default VideoItem
