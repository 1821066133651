/** @jsx jsx */
import { jsx } from "theme-ui"
import ReactPlayer from "react-player"

const ResponsivePlayer = props => (
  <div
    sx={{
      position: "relative",
      paddingTop: "56.25%",
    }}
  >
    <ReactPlayer
      {...props}
      width="100%"
      height="100%"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
      }}
    />
  </div>
)

export default ResponsivePlayer
