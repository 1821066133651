/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
// import Img from "gatsby-image"
import Imgix from "react-imgix"
// import { useMedia } from 'react-use'

import DownloadOverlay from "./download-overlay"

const MediaItem = ({ media, isFluid, onClick, url }) => {
  const [isHovering, setHovering] = useState(false)
  // const isHoverable = useMedia('(hover: hover)')  // is not a touch device?

  return (
    <DownloadOverlay url={url} fileName={media.Nom_du_fichier}>
      <div
        sx={{
          width: isFluid ? "none" : "300px",
          cursor: "pointer",
        }}
        onClick={onClick}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <Imgix
          sx={{
            transition: "opacity 0.1s ease-in-out",
            opacity: isHovering ? 0.85 : 1,
            width: "100%",
            height: "100%",
          }}
          alt={media.Nom_du_fichier}
          size="400px" // not used?
          width={400} //! static, not good
          src={`https://lesfilmspelleas.imgix.net${new URL(media.Image).pathname}`}
          imgixParams={{
            fit: "crop",
            q: 85,
            // "max-w": 600,
            // w: 400, //! when setted, it is not used!
            ar: "400:250",
            // crop: "faces",
          }}
        />
        {/* {(!isHoverable || isHovering) && ...} */}
      </div>
    </DownloadOverlay>
  )
}

export default MediaItem
