/** @jsx jsx */
import { jsx } from "theme-ui"

const ActualiteCollection = props => (
  <div
    {...props}
    sx={{
      "@supports (display: grid)": {
        display: "grid",
        gap: "30px",
        gridTemplateColumns: [
          "repeat(auto-fill, minmax(220px, 1fr))",
          "repeat(auto-fill, minmax(220px, 1fr))",
          "repeat(auto-fill, minmax(260px, 1fr))",
        ],
      },
      "@supports not (display: grid)": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      },
    }}
  />
)

export default ActualiteCollection
