/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

const MultulineText = ({ text }) => {
  const multiLineText = text && text.match(/[^\r\n]+/g)
  return multiLineText
    ? multiLineText.map((line, i) => (
        <Styled.p sx={{ my: 3, textAlign: "justify" }} key={i}>
          {line}
        </Styled.p>
      ))
    : null
}

export default MultulineText
