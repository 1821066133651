/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import Carousel, { Modal, ModalGateway } from "react-images"
import { MdFileDownload } from "react-icons/md"
import Imgix from "react-imgix"

// import { browserDownloadUrl } from '../utils/helpers';
import Section from "./section"
import Container from "./container"
import PhotoCollection from "./photo-collection"
import PhotoItem from "./photo-item"

const View = ({ data }) => {
  const urlObj = new URL(data.media.Image)
  const image = urlObj.pathname
  return (
    <Imgix
      sx={{
        // maxWidth: "100%",
        // maxHeight: "50%",
        width: "100%",
        // height: "50%",
        // objectFit: "contain",
      }}
      src={`https://lesfilmspelleas.imgix.net${image}`}
      size={"100vw"}
      imgixParams={{
        // fit: "crop",
        q: 85,
        // "max-w": 600,
        // w: 400,
        // ar: "400:250",
        // crop: "faces",
      }}
    />
  )
}

const SectionFilmPhotos = props => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const isGrey = props.isGrey
  const film = props.film

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const medias = film.Medias.map(
    media =>
      media.Type === "PHOTO" &&
      media.Image &&
      !media.DELETED &&
      media
  ).filter(media => media) // filter the nulls


  const photos = medias.map(media => {
    return {
      media: media,
      caption: media.Nom_du_fichier
        ? media.Nom_du_fichier.replace(/\.[^/.]+$/, "")
        : "",
    }
  })

  const dossierPress = film.Medias.map(
    media => media.Type === "FILE" && media.Fichier && !media.DELETED && media
  ).filter(media => media)

  return (
    <div>
      <Section isGrey={isGrey}>
        <Section.Title1>PHOTOS</Section.Title1>
        <Container isFull={false} sx={{ my: 4 }}>
          <PhotoCollection>
            {medias.map(
              (mediaThumb, i) =>
                medias[i] && (
                  <PhotoItem
                    isFluid={true}
                    key={mediaThumb.id}
                    media={mediaThumb}
                    onClick={e => {
                      setCurrentImage(i)
                      setViewerIsOpen(true)
                    }}
                    url={medias[i].Image}
                  />
                )
            )}
          </PhotoCollection>
          {dossierPress[0] && (
            <div
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                mt: "20px",
                // justifyContent: 'center',
              }}
              //! CORS problem as the file is on s3
              // onClick={() => browserDownloadUrl(dossierPress[0].Fichier, 'Dossier_press.pdf')}
              onClick={() =>
                window && window.open(dossierPress[0].Fichier, "_blank")
              }
            >
              <p sx={{ fontWeight: "heading", color: "gray" }}>
                DOSSIER DE PRESSE
              </p>
              <MdFileDownload
                sx={{ pl: "7px" }}
                color={"#777"}
                size={"1.9em"}
              />
            </div>
          )}
        </Container>
      </Section>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos}
              components={{ View: View }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

export default SectionFilmPhotos
