/** @jsx jsx */
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { jsx, Styled } from "theme-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MultilineText from "../components/multiline-text"
import Section from "../components/section"
import Container from "../components/container"
import FilmItem from "../components/film-item"
import FilmCollection from "../components/film-collection"
import ArtnumCollection from "../components/artnum-collection"
import ArtnumItem from "../components/artnum-item"
import ActualiteItem from "../components/actualite-item"
import ActualiteCollection from "../components/actualite-collection"
import SectionFilmVideos from "../components/section-film-videos"
import SectionFilmPhotos from "../components/section-film-photos"
import FilmItemEnprod from "../components/film-item-enprod"

const MainBox = props => (
  <div
    {...props}
    sx={{
      display: ["block", "flex"],
      flexDirection: "row",
    }}
  />
)

const PictureBox = props => (
  <div
    {...props}
    sx={{
      display: ["none", "flex"],
      justifyContent: "center",
      width: ["100%", "30%"],
      marginTop: ["0px", "0px"],
      alignItems: "flex-start",
    }}
  />
)

const Picture = ({ personne, ...props }) => {
  return (
    personne &&
    personne.Image_principale_optimized &&
    personne.Image_principale_optimized.childImageSharp && (
      <Img
        {...props}
        fadeIn={true}
        durationFadeIn={300}
        sx={{ width: "100%", mr: [0, 3, 3, 3] }}
        fluid={personne.Image_principale_optimized.childImageSharp.fluid}
        alt={personne.CACHE_Nom_complet}
        placeholderStyle={{ opacity: 0.1, filter: "blur(10px)" }}
      />
    )
  )
}

const TitleBox = props => (
  <div
    {...props}
    sx={{
      width: ["100%", "70%"],
      px: [0, 1, 2, 3],
      py: [4, "inherit"],
    }}
  />
)

function compareField(field = "sortie", sort = "DESC") {
  const compare = (a, b) => {
    if (sort === "DESC") {
      if (a[field] < b[field]) return 1
      if (a[field] > b[field]) return -1
    } else if (sort === "ASC") {
      if (a[field] > b[field]) return 1
      if (a[field] < b[field]) return -1
    }
    return 0
  }
  return compare
}

export default function Template({ data }) {
  const personne = data.bubblePersonne
  const filmsSoon = personne.fields.Films.filter(
    film => film.Statut_ !== "1555693173056x139561298485978430"
  )
  const filmsPelleas = personne.fields.Films.filter(
    film => film.Sortie && film.Statut_ === "1555693173056x139561298485978430"
  )
  const autresFilms = personne.AutresFilms ? personne.AutresFilms : []
  const films = [...filmsPelleas, ...autresFilms].sort(
    compareField("Sortie", "DESC")
  )
  const artnums = personne.fields.Artnums.sort(compareField("sortie", "DESC"))
  const actualites = personne.fields.actualites.sort(
    compareField("Publication_date", "DESC")
  )

  let isGrey = false

  return (
    <Layout>
      <SEO
        title={personne.CACHE_Nom_complet ? personne.CACHE_Nom_complet : ""}
        description={personne.Bio_FR ? personne.Bio_FR : ""}
        personne={personne}
      />

      <Section isGrey={false}>
        <Picture
          sx={{ display: ["inherit", "none"], mt: "-32px" }}
          personne={personne}
        />
        <Container isFull={false} isText={true}>
          <MainBox>
            {personne.Image_principale_optimized &&
              personne.Image_principale_optimized.childImageSharp && (
                <PictureBox>
                  <Picture personne={personne} />
                </PictureBox>
              )}
            <TitleBox>
              <Styled.h1>{personne.CACHE_Nom_complet}</Styled.h1>
              <MultilineText text={personne.Bio_FR} />
            </TitleBox>
          </MainBox>
        </Container>
      </Section>

      {films &&
        films.some(creations => !creations.isArtNum && !creations.DELETED) && (
          <Section isGrey={(isGrey = !isGrey)}>
            <Container isFull={false}>
              <Section.Title1 sx={{ marginBottom: "22px" }}>
                FILMS
              </Section.Title1>
              <FilmCollection>
                {filmsSoon.length > 0 &&
                  filmsSoon.map(film => (
                    <FilmItemEnprod
                      key={film.id}
                      film={film}
                      isHorizontal={false}
                      year={true}
                      isBgGray={!isGrey}
                    />
                  ))}
                {films.map(
                  film =>
                    film.Affiche_optimized &&
                    !film.isArtNum &&
                    !film.DELETED && (
                      <FilmItem
                        isFluid={true}
                        key={film.id}
                        film={film}
                        year={true}
                      />
                    )
                )}
              </FilmCollection>
            </Container>
          </Section>
        )}

      {artnums &&
        artnums.some(creations => creations.isArtNum && !creations.DELETED) && (
          <Section isGrey={(isGrey = !isGrey)}>
            <Container isFull={false}>
              <Section.Title1 sx={{ marginBottom: "22px" }}>
                ART NUMÉRIQUE
              </Section.Title1>
              <ArtnumCollection>
                {artnums.map(
                  artNum =>
                    artNum.Affiche_optimized &&
                    artNum.isArtNum && (
                      <ArtnumItem
                        isFluid={true}
                        key={artNum.id}
                        artNum={artNum}
                      />
                    )
                )}
              </ArtnumCollection>
            </Container>
          </Section>
        )}

      {// check if there are videos in the media array
      personne.Medias &&
        personne.Medias.some(
          media => media.Type === "VIDEO" && media.Video_URL
        ) && <SectionFilmVideos isGrey={(isGrey = !isGrey)} film={personne} />}

      {// check if there are photos in the media array
      personne.Medias &&
        personne.Medias.some(media => media.Type === "PHOTO") && (
          <SectionFilmPhotos isGrey={(isGrey = !isGrey)} film={personne} />
        )}

      {actualites && actualites.some(actualite => !actualite.DELETED) && (
        <Section isGrey={(isGrey = !isGrey)}>
          <Container isFull={false}>
            <Section.Title1 sx={{ marginBottom: "22px" }}>
              ACTUALITÉ
            </Section.Title1>
            <ActualiteCollection>
              {actualites.map(
                actualite =>
                  actualite.Image_principale_optimized && (
                    <ActualiteItem
                      isFluid={true}
                      key={actualite.id}
                      actualite={actualite}
                    />
                  )
              )}
            </ActualiteCollection>
          </Container>
        </Section>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    bubblePersonne(id: { eq: $id }) {
      id
      CACHE_Nom_complet
      Prenom
      Nom
      Allocine_URL
      Bio_FR
      DELETED
      Featured
      IMDB_URL
      PUBLIE
      isArtiste
      isReal
      slug

      fields {
        Films: creations {
          Titre_FR
          slug
          isArtNum
          Sortie
          DELETED
          id
          Statut_

          Affiche_optimized {
            childImageSharp {
              fluid(
                maxWidth: 210
                maxHeight: 290
                quality: 85
                cropFocus: CENTER
              ) {
                # ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluid
              }
            }
          }
          EnProdMedias {
            Titre
            Image_optimized {
              childImageSharp {
                fluid(maxWidth: 170, maxHeight: 170, quality: 85) {
                  # ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluid
                }
              }
            }
            Image
            Image_meta {
              h
              w
            }
          }
          Realisateurs {
            Nom
            CACHE_Nom_complet
            slug
            Image_principale_optimized {
              childImageSharp {
                fluid(maxWidth: 170, maxHeight: 170, quality: 85) {
                  # ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }

        Artnums: creations {
          Titre_FR
          CACHE_Realisateurs
          slug
          isArtNum
          DELETED
          id
          Affiche_optimized {
            childImageSharp {
              fluid(
                maxWidth: 400
                maxHeight: 255
                quality: 85
                cropFocus: CENTER
              ) {
                # ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        actualites {
          Description_FR
          Titre_FR
          PUBLIE
          Lien_URL
          Featured
          DELETED
          id
          Source
          Publication_date
          Image_principale_optimized {
            childImageSharp {
              fluid(
                maxWidth: 370
                maxHeight: 370
                quality: 85
                cropFocus: CENTER
              ) {
                # ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      Image_principale
      Image_principale_optimized {
        childImageSharp {
          fluid(maxWidth: 340, maxHeight: 340, quality: 85, cropFocus: CENTER) {
            # ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluid
          }
        }
      }

      Medias: Medias {
        Titre
        Type
        Video_URL
        Video_type
        id
        Nom_du_fichier
        Fichier
        Image
        DELETED
        Image_meta {
          h
          w
        }
      }

      AutresFilms: AutresFilms {
        Titre_FR
        Sortie
        id
        DELETED
        Affiche_optimized {
          childImageSharp {
            fluid(
              maxWidth: 210
              maxHeight: 290
              quality: 85
              cropFocus: CENTER
            ) {
              # ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
