/** @jsx jsx */
import { jsx } from "theme-ui"

const PhotoCollection = props => {
  return (
    <div
      {...props}
      sx={{
        "@supports (display: grid)": {
          display: "grid",
          gap: "3px",
          gridTemplateColumns: [
            "repeat(auto-fill, minmax(150px, auto))",
            "repeat(auto-fill, minmax(310px, 1fr))",
          ],
        },
        "@supports not (display: grid)": {
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        },
      }}
    />
  )
}

export default PhotoCollection
