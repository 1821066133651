/** @jsx jsx */
import { jsx } from "theme-ui"
import { MdFileDownload } from "react-icons/md"

import { browserDownloadUrl } from "../utils/helpers"

const DownloadOverlay = ({ url, fileName, children }) => {
  const handleDownloadClick = e => {
    e.stopPropagation()
    browserDownloadUrl(url, fileName)
  }
  return (
    <div
      sx={{
        position: "relative",
      }}
    >
      {children}
      <div
        sx={{
          position: "absolute",
          top: "0px",
          right: "0px",
          backgroundColor: "rgba(70,70,70,0.5)",
          opacity: 0.8,
          ":hover": {
            opacity: 1,
          },
          cursor: "pointer",
        }}
      >
        <div
          sx={{
            width: "34px",
            height: "34px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MdFileDownload
            color={"#FFF"}
            size={"25px"}
            onClick={handleDownloadClick}
          />
        </div>
      </div>
    </div>
  )
}

export default DownloadOverlay
