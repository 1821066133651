/** @jsx jsx */
import { jsx } from "theme-ui"

const VideoCollection = props => {
  return (
    <div
      {...props}
      sx={{
        "@supports (display: grid)": {
          display: "grid",
          gap: "10px",
          gridTemplateColumns: [
            "repeat(auto-fill, minmax(180px, 1fr))",
            "repeat(auto-fill, minmax(310px, 1fr))",
          ],
        },
        "@supports not (display: grid)": {
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        },
      }}
    />
  )
}

export default VideoCollection
