/** @jsx jsx */
import { jsx } from "theme-ui"
import Section from "./section"
import Container from "./container"
import VideoCollection from "./video-collection"
import VideoItem from "./video-item"

const SectionFilmVideos = props => {
  const isGrey = props.isGrey
  const film = props.film
  return (
    <Section isGrey={isGrey}>
      <Section.Title1>VIDÉOS</Section.Title1>
      <Container isFull={false} sx={{ my: 4 }}>
        <VideoCollection>
          {film.Medias.map(
            media =>
              media.Type === "VIDEO" &&
              media.Video_URL &&
              !media.DELETED && (
                <VideoItem
                  isFluid={true}
                  key={media.id}
                  url={media.Video_URL}
                  legend={media.Titre}
                />
              )
          )}
        </VideoCollection>
      </Container>
    </Section>
  )
}

export default SectionFilmVideos
