/** @jsx jsx */
import Img from "gatsby-image"
import { Link } from "gatsby"
import { jsx } from "theme-ui"

const Container = ({ ...props }) => (
  <div
    {...props}
    sx={{
      transition: "opacity 0.1s ease-in-out",
      ":hover": {
        opacity: 0.7,
      },
    }}
  />
)

const ThumbnailHorizontal = ({ isBgGray, ...props }) => {
  return (
    <div
      {...props}
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateRows: "1fr 1fr",
        gridGap: "0px",
        transition: "background-color 0.1s ease-ii-out",
        backgroundColor: isBgGray ? "lightgray" : "#FFF",
        ":hover": {
          backgroundColor: "lightgray",
        },
      }}
    />
  )
}

const ThumbnailVertical = ({ isBgGray, ...props }) => {
  return (
    <div
      {...props}
      sx={{
        px: "20px",
        pt: "3px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr 1fr 1fr",
        gridGap: "0px",
        transition: "background-color 0.1s ease-in-out",
        backgroundColor: isBgGray ? "lightgray" : "#FFF",
        "> div:nth-child(1)": {
          gridColumn: ["1/1"],
          gridRow: ["1/1"],
        },
        "> div:nth-child(2)": {
          gridColumn: ["1/1"],
          gridRow: ["2/2"],
        },
        "> div:nth-child(3)": {
          gridColumn: ["1/1"],
          gridRow: ["3/3"],
        },
        "> div:nth-child(4)": {
          gridColumn: ["2/2"],
          gridRow: ["1/1"],
        },
        "> div:nth-child(5)": {
          gridColumn: ["2/2"],
          gridRow: ["2/2"],
        },
        "> div:nth-child(6)": {
          gridColumn: ["2/2"],
          gridRow: ["3/3"],
        },
      }}
    />
  )
}

const Thumbnail = ({ isHorizontal, isBgGray, ...props }) =>
  isHorizontal ? (
    <ThumbnailHorizontal
      isHorizontal={isHorizontal}
      isBgGray={isBgGray}
      {...props}
    />
  ) : (
    <ThumbnailVertical
      isHorizontal={isHorizontal}
      isBgGray={isBgGray}
      {...props}
    />
  )

const Title = ({ isHorizontal, ...props }) => (
  <div
    {...props}
    sx={{
      color: "legend",
      fontSize: "15px",
      fontWeight: 300,
      textAlign: "center",
      pt: 1,
      pb: 1,
      backgroundColor: isHorizontal ? "inherit" : "#FFF",
      // color: isHorizontal ? "legend" : "legend",
      // minHeight: "1em",
    }}
  />
)

const FilmItemEnprod = ({
  film,
  isFluid,
  isHorizontal = true,
  isBgGray = true,
  isTitle = true,
  year,
}) => {
  const realImg =
    film.Realisateurs &&
    film.Realisateurs[0] &&
    film.Realisateurs[0].Image_principale_optimized.childImageSharp.fluid

  const ListThumbs = props => {
    let Thumbs = []
    for (let i = 2; i < 7; i++) {
      const enProdImg =
        film.EnProdMedias &&
        film.EnProdMedias.find(media => media.Titre === i.toString())
      const img = enProdImg && enProdImg.Image_optimized.childImageSharp.fluid
      Thumbs[i] = img ? <Img fluid={img} alt={film.Titre_FR} /> : <div></div>
    }
    return Thumbs
  }

  return (
    film &&
    realImg && (
      <Container>
        {year && (
          <div sx={{ textAlign: "center", mb: 1, color: "#999" }}>{"2020"}</div>
        )}
        <Link to={`/film/${film.slug}`} sx={{ textDecoration: "none" }}>
          <Thumbnail isHorizontal={isHorizontal} isBgGray={isBgGray}>
            <Img fluid={realImg} alt={film.Titre_FR} />
            <ListThumbs />
          </Thumbnail>
          {isHorizontal && isTitle ? (
            <Title isHorizontal={isHorizontal}>
              Un film de {film.Realisateurs[0].CACHE_Nom_complet}
            </Title>
          ) : (
            isTitle && <Title isHorizontal={isHorizontal}>EN PRODUCTION</Title>
          )}
        </Link>
      </Container>
    )
  )
}

export default FilmItemEnprod
