// UTILS

export const browserDownloadUrl = (url, fileName = "file.txt") => {
  if (window && document && url) {
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    link.setAttribute("target", "_blank")
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

// if (window && document && url) {
//   axios({
//     url: `http://localhost:9000${url}`,
//     method: 'GET',
//     responseType: 'blob', // important
//   }).then((response) => {
//     const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
//     const link = document.createElement('a')
//     link.href = blobUrl
//     link.setAttribute('download', media.Nom_du_fichier)
//     document.body.appendChild(link)
//     link.click()
//     document.body.removeChild(link)
//   })
// }
